import React from "react";
import Card from "./Card";

const CardList = ({ robots }) => {
   //its "map" because of loop
        //"i" is for index 
        //for looping unique key "key={i}"
    return (
        <div>
           {
            robots.map((user, i) => {
            return (
                <Card 
                key={i} 
                id={robots[i].id} 
                name={robots[i].name} 
                email={robots[i].email}
                />
                );
            })
        }
        </div> 
    );
}
export default CardList;